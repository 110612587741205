body {
	background-color: $gray;
	background-image: url(/images/gear_bg.png);
	background-repeat: no-repeat;
	background-position: right 156px;

	#main-container {
		min-height: 700px;
		> .row {
			@media (max-width: $screen-tablet - 1) {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
}
img {
	max-width: 100%;
}

.text-small {
	font-size: 12px;
}
.text-bold {
	font-weight: 600;
}
.text-red {
	color: $red;
}
.text-center-mobile {
	@media (max-width: $screen-tablet - 1) {
		text-align: center;
	}
}

.btn-xs {
	font-size: 10px;
	padding: 3px 8px;
}
.btn-default {
	border: none;
	i {
		padding-left: 8px;
	}
}
.btn-red {
	background-color: $red;
	color: $white;
	&:hover, &:focus {
		color: $white;
	}
}
.btn-gray {
	background-color: $dark-gray;
	color: $white;
}
.btn-light {
	opacity: 0.7;
}
.btn-icon {
	background-color: transparent;
	color: $red;
	&:hover, &:focus {
		color: $darkest-gray;
	}
}
.btn-stick {
	position: absolute;
	bottom: 0;
	right: 0;
}
.left-action {
	margin-left: 15px;
	margin-top: -4px;
}

.bottom-row {
	.btn {
				border-radius: 0;
		-webkit-border-radius: 0;
	}
	.btn.bottom-right {
				border-bottom-right-radius: 4px;
		-webkit-border-bottom-right-radius: 4px;
	}
}
.inline {
	display: inline;
}
.block {
	display: block;
}
.no-list-style {
	list-style: none;
	padding-left: 0;
}

.two-column {
	> div {
		&:first-child {
			margin-left: -15px;
			margin-right: 15px;
			padding-right: 0;
			@media (max-width: $screen-tablet - 1) {
				margin-right: 0;
			}
		}
		&:nth-child(2) {
			padding-right: 0;
			@media (max-width: $screen-tablet - 1) {
				margin-left: -15px;
			}
		}
	}
}

.gutter-right {
	> div {
		padding-left: 0;
		@media (max-width: $screen-tablet - 1) { // mobile - 767px
			padding-left: 15px;
		}
	}
}
.gutter-bottom {
	margin-bottom: 15px;
}
.no-gutter {
	margin-left: -15px;
	margin-right: -15px;
	.form-group:not(.panel-header) {
		margin-left: 0;
		margin-right: 0;
	}
}
.no-pad {
	padding-left: 0;
	padding-right: 0;
}
.no-pad-left {
	padding-left: 0;
}
.no-pad-right {
	padding-right: 0;
}
.no-pull, .form-horizontal .form-group.no-pull {
	margin-left: 0;
	margin-right: 0;
}
.no-pull-left {
	margin-left: 0;
}
.panel-pull {
	margin-left: -20px;
	margin-right: -20px;
}
[class^="col"].col-add-left, [class*="col"].col-add-left {
	padding-left: 0;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}
.col-sm-1-half {
	width: 13.888%;
}
.col-sm-10-half {
	width: 86.222%;
}
.col-sm-6 col-sm-offset-right-6 {
	margin-right: 50%;
}
.div-table {
	display: table;
	table-layout: fixed;
	width: 100%;
	border-top: 0.1em solid $table-border-color;
	> .div-table-cell {
		float: none;
		padding: 10px 0;
		vertical-align: middle;
		border-right: 1px solid $table-border-color;
		&:last-child {
			border-right: none;
		}
	}
	> .div-table-cell.text-right {
		padding-right: 10px;
	}
	> .div-table-cell.text-left {
		padding-left: 10px;
	}
}
.div-table-bordered {
	> .div-table-cell {
		border-bottom: 1px solid $table-border-color;
	}
}
.div-table-head {
	font-weight: bold;
	background-color: $mid-gray;
	border-bottom: 1px solid $dark-gray;
	border-top: 1px solid $dark-gray;
	> div {
		padding: 10px;
		border-left: 1px solid $table-border-color;
	}
}
.div-table-cell {
	display: table-cell;
}

/* Alert Styles */

.alert, .alert-success {
	position: relative;
	padding: 10px 15px;
	.close {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		border-radius: 4px;
		background-color: $alert;
		color: $white;
		padding: 0 10px;
		opacity: 1;
		filter: alpha(opacity=100);
		text-shadow: none;
		&:before {
			content: "Close";
			float: left;
			line-height: 21px;
			font-size: 13px;
			font-weight: normal;
			padding: 0 15px 0 5px;
			text-transform: uppercase;
		}
	}
	> div {
		padding-left: 42px;
		&:before {
			content: "\f071";
			font-family: FontAwesome;
			font-style: normal;
			font-weight: normal;
			text-decoration: inherit;
			color: $white;
			font-size: 18px;
			position: absolute;
			background-color: $alert;
			top: 50%;
					transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			left: 8px;
			width: 40px;
			border-radius: 50%;
			text-align: center;
			line-height: 40px;
		}
	}
}

/* Sweet Alert Styles */
body {
.sweet-alert {
	h2 {
		color: $black;
		font-size: 26px;
		line-height: 32px;
		margin: 20px 0;
		font-weight: 400;
	}
	p {
		font-weight: 400;
		font-size: 14px;
		padding: 0 30px;
		color: $dark-gray;
	}
	border-top: 4px solid $red;
	.sa-icon {
		margin: 0 auto;
		background-color: $btn-blue;
		width: 50px;
		height: 50px;
		border: none;
		border-radius: 0;
		-webkit-border-radius: 0;
		&:before {
			content: "";
			position: absolute;
			width: 0 !important;
			height: 0 !important;
			top: inherit !important;
			bottom: -9px !important;
			margin-left: 0 !important;
			border-radius: 0;
			left: 50% !important;
			transform: translateX(-50%) rotate(0) !important;
			transform-origin:  bottom;
			background: transparent;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-top: 10px solid $btn-blue;
		}
		&:after {
			font-size: 20px;
			font-family: FontAwesome;
			font-style: normal;
			font-weight: normal;
			text-decoration: inherit;
			color: $white;
			position: absolute;
			margin-left: 0 !important;
			border-radius: 0 !important;
			top: 12px !important;
			left: 50% !important;
			transform: translateX(-50%) rotate(0) !important;
		}
		.sa-placeholder, .sa-fix, .sa-body, .sa-dot {
			display: none;
		}
	}
	.sa-icon.sa-info {
		border-color: transparent;
		&:before, &:after {
			background-color: transparent;
		}
		&:after {
			content: "\f129";
		}
	}
	.sa-icon.sa-warning {
		border-color: transparent;
		background-color: $red;
		&:before, &:after {
			background-color: transparent;
		}
		&:before {
			border-top-color: $red;
		}
		&:after {
			content: "\f12a";
		}
	}
	.sa-icon.sa-error {
		border-color: transparent;
		background-color: $red;
		&:before, &:after {
			background-color: transparent;
			transform-origin: 0px;
		}
		&:before {
			border-top-color: $red;
		}
		&:after {
			content: "\f12a";
			animation: inherit;
		}
		.sa-line {
			display: none;
		}
	}
	.sa-icon.sa-success {
		border-color: $green;
		background-color: $green;
		&:before, &:after {
			transform-origin: 0px;
			background-color: transparent;
		}
		&:before {
			border-top-color: $green;
		}
		&:after {
			content: "\f00c";
			animation: inherit;
		}
		.sa-line {
			display: none;
		}
	}
	button {
		font-weight: 400;
		font-size: 15px;
				border-radius: 4px;
		-webkit-border-radius: 4px;
				box-shadow: none !important;
		-webkit-box-shadow: none !important;
		padding: 6px 30px;
	}
	button.cancel {
		background-color: $dark-gray;
	}
}
}

/* Panel Styles */
.mini-panel {
	&:before {
		content: "";
		width: 0;
		height: 0;
		border-left: 18px solid transparent;
		border-right: 18px solid transparent;
		border-top: 18px solid $red;
		position: absolute;
		left: 15px;
		top: -5px;
	}
	.panel-header {
		h3 {
			display: inline-block;
			float: none;
			margin-bottom: 12px;
			margin-top: 4px;
			margin-left: 0;
			background-color: transparent;
			color: $black;
			padding: 0 0 0 32px;
			&:before {
				display: none;
			}
		}
	}
	.leading-icon {
		top: 2px;
		left: 0;
	}
}
.run-panel {
	.panel-header {
		h3 {
			font-size: 14px;
			font-weight: 600;
			line-height: 19px;
		}
	}
	.img-thumbnail {
		padding: 0;
		background-color: transparent;
		border: none;
		margin-bottom: 10px;
	}
	.well {
		box-shadow: none;
		border-radius: 0;
		background-color: transparent;
		border: none;
		.well-row {
			border: none;
		}
	}
}
.panel {
	background-color: white;
	padding: 15px 20px 20px;
	position: relative;
	border-top: 5px solid $red;
	border-left: none;
	border-right: none;
	border-bottom: none;
	box-shadow: $panel-shadow;
	&.flush-bottom {
		padding: 15px 20px 0 20px;
	}
	> .row {
		margin-left: 0;
		margin-right: 0;
	}
	> form > .row {
		margin-left: 0;
		margin-right: 0;
	}
	.form-horizontal {
		> .row {
			margin-left: 0;
			margin-right: 0;
		}
	}
	> form > .row.no-gutter {
		margin-left: -20px;
		margin-right: -20px;
	}
	> form > .panel-footer.row.no-gutter {
		padding: 0;
		border-left: none;
		border-bottom: none;
		border-right: none;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}
	.row.no-gutter {
		margin-left: -20px;
		margin-right: -20px;
	}
	.pagination {
		margin: 10px 0 0;
	}
}
.panel.no-caret {
	&:before {
		display: none;
		visibility: hidden;
	}
}

.panel-header {
	> div {
		padding: 0;
	}
	.text-right {
		padding: 0 0 6px 0;
		@media (max-width: $screen-tablet - 1) {
			text-align: left;
			margin-bottom: 6px;
		}
		.btn {
			margin-bottom: 6px;
		}
	}
	h3 {
		position: relative;
		float: left;
		font-size: 16px;
		margin-top: -15px;
		margin-left: -20px;
		padding: 10px 16px 10px 62px;
		background-color: $black;
		color: $white;
		border: 1px solid $black;
		&:before, &:after {
			content: "";
			width: 0;
			height: 0;
			border-top: 8px solid transparent;
			border-bottom: 8px solid transparent;
			position: absolute;
		}
		&:before {
			border-left: 8px solid $black;
			right: -8px;
			top: 12px;
		}
		&:after {
			border-left: 8px solid $black;
			right: -7px;
			top: 12px;
		}
		@media (max-width: $screen-tablet - 1) {
			margin-bottom: 0;
		}
		span {
			font-size: 14px;
			display: inline-block;
		}
	}
	flag {
		i.leading-icon {
			position: static;
			color: $red;
			background-color: $white;
			height: 28px;
			width: 28px;
			line-height: 28px;
			border-radius: 50%;
		}
		i.green-icon {
			color: $green;
		}
	}
	.btn {
		@media (max-width: $screen-tablet - 1) {
			/* padding-left: 0; */
		}
		i {
			padding-left: 0;
		}
	}
}
.panel-footer.no-gutter {
	margin-bottom: -20px;
	margin-left: -20px;
	margin-top: 15px;
	padding: 0;
}

.panel.no-pad {
	padding: 15px 0 0;
	> .panel-header {
		padding: 0 20px;
	}
	/* .text-right {
		.btn {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-bottom-left-radius: 0;
		}
	} */
}

i.leading-icon.fa-flip-horizontal {
	&:after {
		border-left: none;
		border-right: 8px solid $red;
		right: 46px;
	}
}

.striped-bar {
	background: $dark-gray url("../images/background_lines.png") repeat;
	height:35px;
}
.flash {
	display: inline-block;
	position: absolute;
	bottom: 15px;
	right: 15px;
	z-index: 100;
	width: 250px;
}
.header-number {
    float: left;
    margin-left: 15px;
    margin-top: -4px;
}

/**
 * Icon Stylings
 */
.red-icon {
	color: $red;
}
.green-icon {
	color: $green;
}
i.leading-icon, .panel-header flag {
	color: $white;
	background-color: $red;
    left: -1px;
    top: -1px;
    position: absolute;
    bottom: -1px;
    width: 46px;
    text-align: center;
    line-height: 40px;
	&:after {
		content: "";
		width: 0;
		height: 0;
		border-top: 8px solid transparent;
		border-bottom: 8px solid transparent;
		border-left: 8px solid $red;
		position: absolute;
		right: -8px;
		top: 12px;
	}
}
i.leading-icon.fa-flip-horizontal {
	&:after {
		border-left: none;
		border-right: 8px solid $red;
		right: 46px;
	}
}

/* Table Styles */
.table {
	margin-bottom: 0;
}
.table-striped {
	th {
    	background-color: $mid-gray;
    	a {
	    	color: $black;
    	}
	}
	> thead:first-child {
		> tr:first-child {
			> th {
				padding: 10px 8px;
				border-bottom: 1px solid $dark-gray;
				border-top: 1px solid $dark-gray;
			}
		}
	}
	> tbody {
		font-size: 13px;
		> tr.edit-form {
			&:nth-of-type(odd) {
				background-color: $white;
			}
		}
	}
	td {
		a {
			color: $red;
		}
		.btn-success {
			color: $white;
		}
		.btn-primary {
			color: $white;
		}
		.btn-danger {
			color: $white;
		}
		.btn-warning {
			color: $white;
		}
	}
}
.table-thumb {
	width: 60px;
}

.learn-more {
	text-transform: uppercase;
	font-weight: 600;
}

.floating-modal {
	.panel, .nav-tabs {
		box-shadow: 1px 8px 22px -2px rgba(0, 0, 0, 0.7);
		-webkit-box-shadow: 1px 8px 22px -2px rgba(0, 0, 0, 0.7);
	}
	.modal-content {
		background-color: transparent;
		box-shadow: none;
		-webkit-box-shadow: none;
		border: none;
		.modal-header {
			border-bottom: none;
			padding: 0 0 15px 0;
		}
		.modal-body {
		  	padding-bottom: 0;
		}
		.modal-footer {
			padding: 0;
			border-top: none;
			position: absolute;
			top: 46px;
			right: 12px;
			.btn + .btn {
			  	margin: 0;
			}
		}
		.fa-asterisk {
			color: $red;
			font-size: 12px;
		}
	}
}

/* Dropzone */
.dropzone {
    width: 100%;
    border-color: $white;
    border-style: solid;
    border-width: 20px 44px;
    background-color: $btn-blue;
    outline: 1px solid #CCC;
    color: #fff;
    padding: 0;
    min-height: inherit;
    line-height: 36px;
	text-align: center;
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
	    border-width: 20px 25px;
	}
    @media (min-width: $screen-lg-min) {
	    border-width: 20px 50px;
	}
    i {
	    padding-right: 6px;
    }
	> span {
		&:not(.btn) {
			display: block;
			background-color: $white;
			color: $dark-gray;
		}
	}
	.dz-message {
		padding: 8px 0;
		margin: 0;
		font-weight: 600;
		line-height: 14px;
		background-color: $white;
		color: $darkest-gray;
	}
	.dz-preview.dz-image-preview {
		background-color: $white;
		margin: 0;
		padding: 0 26px;
		width: auto;
		.dz-progress {
			display: none;
			margin-top: -24px;
		}
		> button {
			background-color: $red;
			color: $white;
			border: none;
			padding: 0 20px;
			margin-top: 10px;
			font-size: 12px;
			line-height: 28px;
			border-radius: 4px;
		}
	}
}

/* Sidebar Styles */

.sidebar {
	@media (max-width: $screen-desktop - 1) {
		padding-left: 0;
		padding-right: 0;
	}
	.notes.panel {
		padding: 15px 20px 20px;
		> .panel-header {
			padding: 0;
		}
	}
}
.sidebar-message {
	background-color: $red;
	color: $white;
	padding: 20px 16px;;
	float: left;
	margin-bottom: 20px;
	h4 {
		text-transform: uppercase;
		font-weight: 700;
		margin-top: 0;
	}
	p {
		font-size: 13px;
	}
	a {
		color: $white;
	}
}

/* Loading Styles */
.loading-container {
	padding: 10px;
	text-align: center;
}

.fadeIn.ng-hide-remove {
	-webkit-animation: fadeIn $fade-speed;
	-moz-animation: fadeIn $fade-speed;
	-ms-animation: fadeIn $fade-speed;
	animation: fadeIn $fade-speed;
}
.fadeOut.ng-hide-add {
	-webkit-animation: fadeOut $fade-speed;
	-moz-animation: fadeOut $fade-speed;
	-ms-animation: fadeOut $fade-speed;
	animation: fadeOut $fade-speed;
}

/* Read-Only styling */

.well {
	margin-bottom: 0;
	padding: 5px;
	.well-row {
		border-bottom: 1px solid $table-border-color;
		.well-label {
			font-weight: 600;
			font-size: 12px;
			position: relative;
			border-top: 1px solid darken($light-gray,12%);
			border-left: 1px solid darken($light-gray,12%);
			border-right: 1px solid darken($light-gray,12%);
			background-color: darken($light-gray,7%);
			&:before, &:after {
				content: "";
				width: 0;
				height: 0;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
			&:before {
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				border-left: 5px solid darken($light-gray,7%);
				right: -5px;
				z-index: 10;
			}
			&:after {
				border-top: 6px solid transparent;
				border-bottom: 6px solid transparent;
				border-left: 6px solid darken($light-gray,12%);
				right: -6px;
				z-index: 5;
			}
		}
		> div {
			padding: 4px 8px;
			&:not(.well-label) {
				padding-left: 20px;
				word-wrap: break-word;
				overflow-wrap: break-word;
			}
		}

	}
}

/* Read-Only Form styling */
form, .well-form, .form-panel {
	> .row {
		&:not(.panel-header) {
			padding: 5px;
			background-color: $well-bg;
			border: 1px solid $well-border;
			border-radius: $border-radius-base;
			@include box-shadow(inset 0 1px 1px rgba(0,0,0,.05));
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
			.form-group {
				*zoom: 1;
				display: flex;
				margin: 0 -10px;
				&:before,
				&:after {
					display: table;
					content: "";
  				}
  				&:after {
  					clear: both;
  				}
  				&:last-child {
  					label:not(.radio-inline), .form-label {
						border-bottom: 1px solid darken($light-gray,12%);	  						
	  				}	  				
  				}
  				label:not(.radio-inline), .form-label {
					float: left;
					width: 46%;
					font-weight: 600;
					font-size: 12px;
					padding: 4px 8px;
					margin-right: 4%;
					position: relative;
					margin-bottom: 0;
					border-top: 1px solid darken($light-gray,12%);
					border-left: 1px solid darken($light-gray,12%);
					border-right: 1px solid darken($light-gray,12%);
					background-color: darken($light-gray,7%);
					&:before, &:after {
						content: "";
						width: 0;
						height: 0;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
					}
					&:before {
						border-top: 5px solid transparent;
						border-bottom: 5px solid transparent;
						border-left: 5px solid darken($light-gray,7%);
						right: -5px;
						z-index: 10;
					}
					&:after {
						border-top: 6px solid transparent;
						border-bottom: 6px solid transparent;
						border-left: 6px solid darken($light-gray,12%);
						right: -6px;
						z-index: 5;
					}
				}
				label.time-label {
					width: 19.55%;
					margin-right: 2%;
				}
				.form-control, .form-images, .dropzone, .chosen-container, .ui-spinner {
					float: left;
					width: 50%;
					margin-bottom: 6px;
				}
				.chosen-container {
					width: 50% !important;
				}
				.well-textarea {
					height: 130px !important;
				}
				div[class*="col-"] {
					padding: 0;
					&.form-label {
						padding: 4px 8px;
					}
					.form-control, .chosen-container {
						width: 100% !important;
					}
				}
				.time-group {
					float: left;
					width: 78.45%;
					label {
						background-color: darken($light-gray,4%);						
					}
					.form-group {
						float: left;
						width: auto;
						margin: 0;
						&:last-child {
							input.form-control {
								margin-right: 0;
							}
						}
						label {
							width: auto;
							margin-right: 10px;
						}
						input {
							width: 38px !important;
						}
					}
				}
			}
			.form-group.col-xs-12 {
				margin: 0;
				padding: 0;
			}
			.form-group.no-flex {
				display: block;
				label {
					min-height: 38px;
				}
			}
			.form-group.has-error {
				position: relative;
				padding-bottom: 30px;
				.help-block {
					position: absolute;
					right: 0;
					bottom: 0;
				}
			}
			.full-column {
				margin-top: 10px;					
			}
			.ui-spinner {
				.form-control {
					width: 100%;
				}
			}
		}
	}
	.row.panel-footer {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-right: none;
		border-left: none;
		border-bottom: none;
		&.text-right {
			input, .btn {
				padding-top: 10px;
				padding-bottom: 10px;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
	.row.no-gutter {
		padding: 0;
	}
	.panel-header {
		.form-group.text-right {
			margin-bottom: 0;
		}
	}
}

/* Ajax Styles */
#form-dialog {
	.modal-content {
		min-height: 350px;
		.loading-container {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0px, -50%);
			width: 100%;
		}
	}
}
.modal-content.panel {
	margin-bottom: 0;
	.modal-header.panel-header {
		padding: 0 15px;
		h3 {
			@media (max-width: $screen-tablet - 1) {
				margin-bottom: 18px;
			}
		}
	}
}
.modal-footer {
	.btn {
		padding: 5px 20px;
		+ .btn {
			margin: 10px 10px 10px 0;
		}
	}
}

.preformatted {
  white-space: pre;
  word-wrap: break-word;
}

.measurementEdit {
  padding-top: 5px;
  margin: 2px;
  border-radius: 4px;
}

#vendor-info {
	padding-left: 12px;
}

/* Print Styles */

@media print {
	.panel-header h3 {
		border: none;
		padding-left: 40px;
		&:before, &:after {
			display: none;
		}
	}
	.run-panel {
		.panel-header {
			h3 {
				padding-left: 0;
			}
		}
	}
	i.leading-icon {
		&:after {
			display: none;
		}
	}
	.panel-header flag {
		display: none;
	}
	a[href]:after {
    	content: none;
  	}
  	.btn {
	  	display: none;
  	}
  	#footer-container .footer-bottom {
	  	display: none;
	  	border-top: 1px solid transparent;
	  	background-color: transparent;
  	}
  	.note-edit i, .comment-reply i {
	  	display: none;
  	}
  	.mainbody {
	  	.well-row {
		  	.col-xs-6 {
			  	width: 33.3333%;
		  	}
	  	}
	  	.col-sm-offset-1.col-sm-5 {
		  	.well-row {
			  	&:last-child {
				  	border-bottom: none;
			  	}
		  	}
	  	}
  	}
  	.well .well-row .well-label {
	  	border: none;
	  	&:before, &:after {
		  	display: none;
	  	}
  	}
  	.ui-sortable-handle i {
	  	display: none;
  	}
}

@import "partials/header";
@import "partials/footer";
