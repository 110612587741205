body#pdf-view {
    background-color: white;
    
    .pdf-section {
      /* Prevents a page break from occuring in the middle of a panel */
      page-break-inside: avoid !important;
    }

    .new-page {
      page-break-before: always;
    }
    
    .pdf-well-label {
	    font-weight: bold;
    }
    h2 {
	    font-weight: bold;
	    margin-top: 30px;
    }
    h3 {
	   	padding-bottom: 10px;
	    border-bottom: 1px solid #000;
	    i {
		    padding-right: 5px;
	    }
    }
    .table > thead > tr > th,
    .table > thead > tr > td,
    .table > tbody > tr > th,
    .table > tbody > tr > td,
    .table > tfoot > tr > th,
    .table > tfoot > tr > td {
	    border-top: none;
	    padding: 0;
    }
    .table {
	    > thead {
		    > tr {
			    > th, > td {
				    font-weight: bold;
				    border-bottom: 1px solid transparent;
			    }
		    }
	    }
    }
    .table-thumb {
	    width: 90px;
	    padding-right: 20px !important;
    }
    .no-gutter {
	    margin-left: 0;
	    margin-right: 0;
    }
    .no-pull {
	    > .col-xs-12 {
		    padding-left: 15px;
		    padding-right: 15px;
	    }
    }
    .col-xs-12 {
	    padding-left: 0;
	    padding-right: 0;
    }
    .pdf-panel {
	    > .row {
		    margin-left: 0;
		    margin-right: 0;
	    }
    }
    .pdf-table {
	    padding-left: 15px;
	    padding-right: 15px;
    }
    .tab-table .sort-row:last-child .div-table {
	    border: none;
    }
    .tab-table .sort-row:nth-child(odd) .div-table {
	    background-color: transparent;
    }
    .div-table {
	    border: none;
	    > .div-table-cell {
		    padding: 0;
		    border-right: none;
	    }
    }
}