.header {
	margin-bottom: 30px;
	.navbar {
		margin-top: 10px;
		border: none;
	}
}

/* START: make it rounded */
.nav {
  border-radius: 4px;
}
.navbar-user > li.user-action {
  border-radius: 4px;
}

.user-avatar > i {
  border-radius: 4px 0 0 4px;
}
/* END: make it rounded */

.navbar-logo {
	background-image: url(/images/header_logo.png);
	background-size: cover;
	background-position: center center;
	position: absolute;
	top: -30px;
	left: 0;
	width: 153px;
	height: 82px;
	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		background-image: url(/images/header_logo@2x.png);
		background-size: 153px 82px;
	}
}
.navbar-wrap {
	margin-left: 160px;
	position: relative;
	&:before, &:after {
		content: " ";
		height: 74px;
		position: absolute;
		top: -5px;
		width: 12%;
		z-index: 1;
		opacity: 0;
		background-color: $red;
		@media (max-width: $screen-tablet - 1) {
			display: none;
		}
	}
	@media (max-width: $screen-tablet - 1) {
		margin-left: 0;
	}
	.navbar-nav {
		@media (max-width: $screen-tablet - 1) {
			margin: 0;
		}
	}
}
.no-active {
	.navbar-wrap {
		&:after {
			display: none;
		}
	}
}
.navbar.hover .navbar-wrap:before, .header .navbar-wrap:after {opacity: 1;}
.at_0 .navbar-wrap:before, .active_0 .navbar-wrap:after {
	left: 2%;
	@media (max-width: $screen-desktop - 1) {

	}
}
.at_1 .navbar-wrap:before, .active_1 .navbar-wrap:after {
	left: 14%;
	@media (max-width: $screen-desktop - 1) {
		left: 18%;
	}
}
.at_2 .navbar-wrap:before, .active_2 .navbar-wrap:after {
	left: 26%;
	@media (max-width: $screen-desktop - 1) {
		left: 34%;
	}
}
.at_3 .navbar-wrap:before, .active_3 .navbar-wrap:after {
	left: 38%;
	@media (max-width: $screen-desktop - 1) {
		left: 48.5%;
	}
}
.at_4 .navbar-wrap:before, .active_4 .navbar-wrap:after {
	left: 50%;
	@media (max-width: $screen-desktop - 1) {
		left: 60.5%;
	}
}
.at_5 .navbar-wrap:before, .active_5 .navbar-wrap:after {
	left: 62%;
	@media (max-width: $screen-desktop - 1) {
		left: 72.5%;
	}
}
.navbar-action {
	background-color: $white;
	position: relative;
	z-index: 10;
	box-shadow: 0 0 3px 0 rgba(0,0,0,0.5);
	> li {
		text-align: center;
		width: 16.66%;
		padding-top: 4px;
		a {
			color: $black;
			&:hover,
			&:focus {
				color: $red;
			}
		}
		i {
			font-size: 24px;
			line-height: 32px;
			@media (max-width: $screen-tablet - 1) {
				font-size: 20px;
				width: 22px;
			}
		}
		span {
			display: block;
			line-height: 28px;
			@media (max-width: $screen-tablet - 1) {
				display: inline-block;
				padding-left: 12px;
				font-size: 16px;
			}
		}
		@media (max-width: $screen-desktop - 1) {
			display: table-cell;
			white-space: nowrap;
			float: none;
			width: auto;
		}
		@media (max-width: $screen-tablet - 1) {
			display: block;
			white-space: normal;
			text-align: left;
		}
	}
	> li.active {
		position: relative;
		&:before {
			content: "";
			width: 0;
			height: 0;
			border-left: 12px solid transparent;
			border-right: 12px solid transparent;
			border-top: 12px solid $red;
			position: absolute;
			left: 50%;
			top: -5px;
			margin-left: -12px;
			@media (max-width: $screen-tablet - 1) {
				top: 22px;
				left: 0;
				margin-left: 0;
				border-top: 8px solid transparent;
				border-bottom: 8px solid transparent;
				border-left: 8px solid $red;
				border-right: none;
			}
		}
		a {
			color: $red;
		}
	}
}
.navbar-user {
	position: relative;
	> li.user-avatar {
		position: absolute;
		left: 15px;
		z-index: 10;
		> i {
			width: 36px;
			line-height: 64px;
			font-size: 24px;
			text-align: center;
			background-color: $red;
			color: $white;
		}
		@media (max-width: $screen-tablet - 1) {
			left: 0;
		}
	}
	> li.user-action {
		background-color: $black;
		color: $white;
		font-size: 11px;
		line-height: 18px;
		width: 100%;
		height: 64px;
		margin-left: 15px;
		padding: 4px 0 0 46px;
		@media (max-width: $screen-tablet - 1) {
			width: auto;
		}
		> ul {
			padding-left: 0;
			list-style: none;
			a {
				color: $white;
				padding-right: 6px;
				margin-right: 6px;
				border-right: 1px solid $white;
				&:last-child {
					border-right: none;
				}
			}
		}
	}
}
.navbar-toggle {
	.icon-bar {
		background-color: $red;
	}
}
.navbar-collapse {
	@media (max-width: $screen-tablet - 1) {
		padding-left: 0;
		padding-right: 0;
	}
}