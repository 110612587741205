.mini-part {
	img {
		margin-bottom: 12px;
	}
	.mini-customer {
		font-weight: 700;
	}
}
.mini-detail {
	font-weight: 600;
	margin-top: 12px;
}
.comments {
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
	> li {
		background-color: $light-gray;
	  	border-radius: 4px;
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.comment-header {
	position: relative;
	list-style: none;
	padding-left: 0;
	padding-right: 15px;
	> li {
		float: left;
		font-size: 12px;
		line-height: 32px;
		&.comment-reply {
			a {
				padding-left: 10px;
				@media (max-width: $screen-tablet - 1) {
				position: absolute;
				top: 0;
				right: 10px;
				}
			}
		}
	}
}
.comment-title {
	@media (max-width: $screen-tablet - 1) {
		float: none;
		clear: left;
		padding-left: 15px;
	}
	.comment-name {
		font-size: 14px;
		font-weight: 700;
	}
}
.comment-text {
	padding: 15px;
	margin-bottom: 0;
}

.note-edit {
  margin: 0 10px;

  a {
	@media (max-width: $screen-tablet - 1) {
	  position: absolute;
	  top: 0;
	  right: 10px;
	}
  }
}

.editable-textarea{
  width: 95%;
  margin: 8px 0;

  textarea {
	width: 95% !important;
	margin-left: 10px;
	border-radius: 4px;
	border: 1px solid $mid-gray;
  }
}

.editable-buttons {
  display: block;
  margin: 8px 0;
  button {
    border-radius: 4px;
    border: none;
    color: #FFF;
    text-transform: capitalize;
    background-color: $warning;
    padding: 4px 12px;
    margin-left: 10px;
	&:before {
		font-family: FontAwesome;
		font-style: normal;
		font-weight: normal;
		text-decoration: inherit;
		padding-right: 5px;
	  }
  }
  button[type="button"] {
	  &:before {
		  content: "\f057";
	  }
  }
  button[type="submit"] {
	  background-color: $green;
	  &:before {
		  content: "\f0c7";
	  }
  }
}

.revision-history {
	> li {
		margin: 8px 0;
	}
}
.revision-user {
	font-weight: 700;
	font-size: 16px;
}
.revision-label {
	width: 80px;
	display: inline-block;
}

/* Date Picker */
.ui-datepicker {
	background-color: $white;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
	width: 280px;
	.ui-datepicker-header {
		text-align: center;
		background-color: $gray;
		padding: 10px 8px;
		border-top: 6px solid $red;
		.ui-icon {
			font-size: 12px;
		}
		> a {
			position: relative;
			&:before {
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				text-decoration: inherit;
				position: absolute;
				top: -4px;
				font-size: 20px;
			}
			&:hover, &:focus {
				text-decoration: none;
				cursor: pointer;
			}
			> span {
				background-color: transparent;
				border: 0 none;
				color: transparent;
				font: 0px/0 a;
				text-shadow: none;
			}
		}
		.ui-datepicker-prev {
			float: left;
			&:before {
				content: "\f177"; // Arrow-left
				left: 10px;
    		}
		}
		.ui-datepicker-next {
			float: right;
			&:before {
				content: "\f178"; // Arrow-right
				right: 10px;
    		}
		}
	}
	.ui-datepicker-calendar {
    	text-align: center;
		width: 100%;
		thead {
			border-bottom: 1px solid $light-gray;
		}
		th {
			text-align: center;
		}
		th, td {
			padding: 6px;
		}
		.ui-state-default {
			color: $black;
		}
		.ui-state-highlight {
			background-color: $light-gray;
			border-color: $gray;
		}
		.ui-state-active {
			color: $red;
			font-weight: 600;
		}
	}
}
.datepicker {
	input, ul, ul > li, li > div, li > div > table {
		&:focus {
			outline: none;		
		}
	}
	.dropdown-menu {
		border-top: 6px solid $red;
		border-left: none;
		border-right: none;
		border-bottom: none;
		margin-top: 0;
		padding: 0;
				border-radius: 0;
		-webkit-border-radius: 0;
				box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
		-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
		table {
			thead {
				.btn {
					background-color: $light-gray;
					padding: 8px 10px;
				}
				.btn-default {
					strong {
						font-weight: normal;
						font-size: 13px;
					}
				}
				tr {
					&:last-child {
						border-bottom: 1px solid $light-gray;
						th {
							padding: 8px 0;
						}
					}
				}
			}
		}
		.glyphicon {
			font-family: FontAwesome;
			font-style: normal;
			font-weight: normal;
			text-decoration: inherit;
			font-size: 20px;
			color: $red;			
		}
		.glyphicon-chevron-left:before {
				content: "\f177"; // Arrow-left			
		}	
		.glyphicon-chevron-right:before {
				content: "\f178"; // Arrow-right			
		}
		.btn.active {
					box-shadow: none;
			-webkit-box-shadow: none;
			background-color: $white;
			color: $red;
			.text-info {
				color: $red;
			}
		}
		.btn-sm {
			font-size: 13px;
		}
		.text-muted {
			color: $white;
		}
		.text-info {
			background-color: $light-gray;
			color: $black;
		}
		li {
			&:last-child {
				display: none;
			}
		}
	}
}


.toolpicture-display {
    width: 30%;
    float: left;
    margin-right: 3%;
    margin-bottom: 3%;
    margin-top: 3%;
    img {
	    border-radius: 4px;	    
    }
}
.tool-picture-select {
    width: 40%;
    float: left;
    margin-right: 10%;
    border-radius: 4px;
    img {
	    border-radius: 4px;
    }
}

