body.search {
	.mini-part-panel {
		.panel {
			min-height: 225px;
		}
	}
}

#search-form {
	position: relative;
}
.user-greeting {
	line-height: 32px;
	margin: 24px 0 12px 0;
	font-size: 16px;
	i {
		color: $red;
		font-size: 24px;
		padding-right: 10px;
	}
}
.search-actions {
	position: relative;
    &:after {
	    content: "\f178"; // Arrow-left
	    font-family: FontAwesome;
		font-style: normal;
		font-weight: normal;
		text-decoration: inherit;
		color: $white;
		position: absolute;
		right: 12px;
		top: 10px;
		font-size: 16px;
    }
}
.search-clear {
	border: none;
	color: $red;
}
.search-submit {
	font-size: 16px;
	padding: 6px 36px 6px 18px;
	@media (max-width: $screen-desktop - 1) {
		width: auto;
	}
}
.search-panel {
	position: relative;
	z-index: 50;
	@media (max-width: $screen-desktop - 1) {
	
	}
	@media (max-width: $screen-tablet - 1) {
		padding-bottom: 40px;
	}
	.no-gutter.search-actions {
		@media (max-width: $screen-tablet - 1) {
			margin-bottom: -40px;
		}		
	}
}
.adv-search {
	position: relative;
	z-index: 20;
	padding-left: 0;
	padding-right: 40px;
	margin-top: 8px;
	transition: all 0.35s;
	transform: translateX(0);
	@media (max-width: $screen-desktop - 1) {
		margin-top: 0;
		padding-left: 6px;
		padding-right: 6px;
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}
.adv-open {
	.adv-search, .adv-search-toggle {
		@media (min-width: $screen-desktop) {
			transform: translateX(-283px);
		}
		@media (min-width: $screen-lg-desktop) {
			transform: translateX(-350px);
		}	
	}
	.fa-gear {
    	-ms-transform:rotate(180deg);
		-moz-transform:rotate(180deg);
		-webkit-transform:rotate(180deg);
		transform:rotate(180deg);
	}
}
.adv-search-panel {
    border-radius: 0 4px 4px 0;
	background-color: $white;
	padding: 10px 0 10px 15px;
	@media (max-width: $screen-tablet - 1) {
	    border-radius: 0 0 4px 4px;
		padding-right: 15px;
	}
	h4 {
		font-size: 16px;
		color: $red;
		margin-top: 0;
		margin-bottom: 10px;
	}
	.form-group {
		margin-bottom: 10px;
		margin-left: 0;
		label {
			font-size: 13px;
			line-height: 30px;
			margin-bottom: 0;
		}
	}
}
.adv-search-toggle {
    border-radius: 0 4px 4px 0;
	background-color: $red;
	color: $white;
	font-size: 24px;
	position: absolute;
	top: 10px;
	right: 0;
	width: 40px;
	line-height: 40px;
	text-align: center;
	z-index: 30;
	transition: all 0.35s;
	transform: translateX(0);
	&:hover {
		background-color: $gray;
		color: $red;
	}
	&:focus {
		color: $white;
		background-color: $red;
	}
	@media (max-width: $screen-desktop - 1) {
	    border-radius: 0 0 0 4px;
		z-index: 60;
		top: 5px;	
	}
}
.fa-gear {
	transition: all 0.5s linear;
	transform:rotate(0deg);
}
.form-group {
	label, .form-label {
		padding-right: 0;
		padding-left: 0;
	}
	label.radio-inline, label.checkbox-inline {
		padding-left: 20px;
	}
}
