.qa-status {
  display: inline-block;
  height: 48px;
  background-color: $red;
  color: $white;
  line-height: 28px;
  font-size: 18px;
  text-transform: uppercase;
  padding: 10px 32px 10px 0;
  font-weight: 600;
  position: relative;
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid $red;
    position: absolute;
    top: 17px;
    right: -10px;
  }
  @media (max-width: $screen-tablet - 1) {
    display: block;
    text-align: center;
    &:after {
      display: none;
    }
  }
  i {
    padding: 0 12px;
  }
}
.qa-status.open {
  background-color: $green;
  &:after {
    border-left-color: $green;
  }
}
.qa-status.closed {
  background-color: $red;
  &:after {
    border-left-color: $red;
  }
}
.qa-status.requiresfollowup {
  background-color: $gray-light;
  &:after {
    border-left-color: $gray-light;
  }
}
.qa-action {
  float: right;
  margin-right: 9px;
  margin-top: 9px;
  padding: 5px 20px;
  @media (max-width: $screen-tablet - 1) {
    float: none;
    padding: 10px 0;
    width: 200px;
    margin: 0 auto;
    display: block;
  }
}

.qa-form-horizontal {
  display: inline;
}

.corrective-modal {
	.nav-tabs {
		> li.active {
			> a {
				background-color: $red;
				color: $white;
			}
		}
		> li {
			> a {
				&:hover, &:focus {
					background-color: $red;
					color: $white;
				}
			}
		}
	}
}
