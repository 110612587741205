/**
 * Override Bootstrap Variables here
 **/
 
$navbar-padding-vertical: 0;
$navbar-margin-bottom: 0;
$nav-link-hover-bg: transparent;
/*
$border-radius-base: 0;
$border-radius-small: 0;
$border-radius-large: 0;
*/
$table-cell-padding: 10px;
$table-bg-accent: $light-gray;

$link-color: $red;
$link-hover-color: $dark-gray;

$pagination-active-bg: $red;
$pagination-active-border: $red;

$font-size-base: 13px;
$font-size-h3: ceil(($font-size-base * 1.35)) !default; // ~20px

$alert-padding: 10px 99px 10px 15px;
$alert-warning-bg: $light-gray;
$alert-warning-border: transparent;
$alert-warning-text: $dark-gray;
$alert-success-bg: $light-gray;
$alert-success-border: transparent;
$alert-success-text: $dark-gray;

$btn-success-bg: $green;
$btn-danger-bg: $red;

$input-color: $black;
$input-border-radius: 0;
