#footer-container {
	
	.footer-bottom {
		padding: 20px;
		color: $gray;
		background-color: $darkest-gray;
		height: 100px;
		border-top: 30px solid $dark-gray;
		margin-top: 40px;
	}
} 