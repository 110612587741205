.administration {
	.navbar-wrap {
		&:before, &:after {
			//display: none;
		}
	}
	.navbar-action {
		//box-shadow: none;
		//background-color: transparent
	}
	.sidebar-nav {
		ul {
			list-style: none;
			padding-left: 0;
			> li {
				> a {
					display: block;
					color: $black;
					font-size: 16px;
					font-weight: 600;
					padding: 8px 8px 8px 0;
					position: relative;
				}
				&.has-drop {
					> ul {
						padding-left: 15px;
						> li {
							> a {
								font-weight: 400;
							}
						}
					}
					> a {
						&:after {
							content: "\f078"; /* chevron-down */
							font-family: 'FontAwesome';
							position: absolute;
							right: 0;
						}
					}
					> a[aria-expanded="true"] {
						&:after {
							content: "\f077"; /* chevron-up */
						}
					}
				}
			}
		}
	}
	.panel-header {
		h3 {
			margin-bottom: 0;
		}
	}
	.panel-body {
		.search {
			margin-bottom: 15px;		
		}
	}
	.selectize-input {
		padding-right: 30px;
	}
	.search {
		.selectize-control {
			width: 200px;
			text-align: left;
		}
	}
	.dropdown {
		.form-group {
			margin-left: 0;
			margin-right: 0;
		}
	}
	.nav-tabs > li.active > a,
	.nav-tabs > li.active > a:hover,
	.nav-tabs > li.active > a:focus,
	.nav-tabs > li > a:hover {
		background-color: $dark-gray;
	}

	.break-word {
		word-wrap: break-word;
	}
	.checkbox {
		.fa {
			position: absolute;
			margin-left: -20px;
			top: 4px;
		}
	}
}

/* Bootstrap Date Picker */

.bootstrap-datetimepicker-widget {
	border-top: 6px solid $red;
	table {
		td.active {
			background-color: $red;
			&:hover {
				background-color: $red;
			}
		}
	}
	.btn-primary {
		background-color: $red;
		border: none;
	}
}
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
	display: none;
}