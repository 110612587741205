.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
	border: 1px solid #fcefa1/*{borderColorHighlight}*/;
	background: #fbf9ee/*{bgColorHighlight}*/;
	color: #363636/*{fcHighlight}*/;
}
.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
	color: #363636/*{fcHighlight}*/;
}