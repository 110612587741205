$opslist-item-height: 40px;
#opslist {
	.ui-sortable {
		margin-bottom: 0;
	}
}
.ui-sortable {
    list-style: outside none none;
    padding-left: 0;
    float: left;
    width: 100%;
    > li {
	    border-top: 1px solid #CCC;
	    float: left;
	    width: 100%;
	    height: $opslist-item-height;
	    position: relative;
	    &:last-child {
		    > .group-label {
			    border-bottom-left-radius: 4px;   
		    }
	    }
    }
    > li.active {
	    background-color: $light-gray;
	    > .individual-label {
		    font-weight: 600;
	    }
    }
    .group-label {
	    width: 80px;
	    background-color: $dark-gray;
	    color: #FFF;
	    font-size: 12px;
	    float: left;
	    height: $opslist-item-height;
	    padding: 4px 0 0 4px;
	    &:after {
		    content: "";
		    width: 0; 
			height: 0; 
			border-top: 8px solid transparent;
			border-bottom: 8px solid transparent;
			border-left: 8px solid $dark-gray;
			position: absolute;
			left: 80px;
			top: 12px;
			
	    }
    }
    .individual-label {
	    font-size: 13px;
	    float: left;
	    line-height: $opslist-item-height;
	    padding-left: 14px;
		color: $black;
		.fa-circle {
			color: $red;
			font-size: 11px;
			padding: 0 4px;
		}
		.cert {
			.fa-circle {
				color: $green;
			}
		}
    }
    .turn {
	    .group-label {
		    background-color: $turning-color;
		    &:after {
			    border-left-color: $turning-color;
		    }
	    }
    }
    .mill {
	    .group-label {
		    background-color: $milling-color;
		    &:after {
			    border-left-color: $milling-color;
		    }
	    }
    }
    .aux {
	    .group-label {
		    background-color: $aux-color;
		    &:after {
			    border-left-color: $aux-color;
		    }
	    }
    }
    .insp {
	    .group-label {
		    background-color: $inspection-color;
		    &:after {
			    border-left-color: $inspection-color;
		    }
	    }
    }
}
.dragger {
    cursor: pointer;
    font-size: 16px;
    display: block;
    text-align: right;
    padding-right: 15px;
    line-height: 40px;
    .fa-ellipsis-v {
	    font-size: 14px;
	    padding: 0 1px;
    }
}
table {
	.dragger {
		padding-right: 0;
		line-height: inherit;
		text-align: center;
		.fa-ellipsis-v {
			padding: 0;
		}
	}
}

/* New Ops Dropdown */

.new-ops {
	.dropdown-menu {
		padding: 0;
	}
	.mill {
		border-left: 8px solid $milling-color;
	}
	.turn {
		border-left: 8px solid $turning-color;
	}
	.aux {
		border-left: 8px solid $aux-color;
	}
	.insp {
		border-left: 8px solid $inspection-color;
	}
}


/* Op-specific panel colors */

.mill {
	.mainbody {
		.panel {
			border-top-color: $milling-color;
			&:before {
				border-top-color: $milling-color;
			}
		}
		.panel-header {
			h3 {
				i {
					background-color: $milling-color;
					&:after {
						border-left-color: $milling-color;
					}				
				}
			}
		}
		.nav-tabs {
			margin-bottom: 20px;
			> li.active {
				> a {
					&, &:hover, &:focus {
						background-color: $milling-color;	
					}
				}
			}
			> li {
				> a {
					&:hover, &:focus {
						background-color: $milling-hover-color;
					}
				}
			}
		}
	}
	.modal {
		.panel {
			border-top-color: $milling-color;
			&:before {
				border-top-color: $milling-color;
			}
		}
	}
}
.turn {
	.mainbody {
		.panel {
			border-top-color: $turning-color;
			&:before {
				border-top-color: $turning-color;
			}
		}
		.panel-header {
			h3 {
				i {
					background-color: $turning-color;
					&:after {
						border-left-color: $turning-color;
					}				
				}
			}
		}
		.nav-tabs {
			margin-bottom: 20px;
			> li.active {
				> a {
					&, &:hover, &:focus {
						background-color: $turning-color;	
					}
				}
			}
			> li {
				> a {
					&:hover, &:focus {
						background-color: $turning-hover-color;
					}
				}
			}
		}
	}
	.modal {
		.panel {
			border-top-color: $turning-color;
			&:before {
				border-top-color: $turning-color;
			}
		}
	}
}
.aux {
	.mainbody {
		.panel {
			border-top-color: $aux-color;
			&:before {
				border-top-color: $aux-color;
			}
		}
		.panel-header {
			h3 {
				i {
					background-color: $aux-color;
					&:after {
						border-left-color: $aux-color;
					}				
				}
			}
		}
		.nav-tabs {
			margin-bottom: 20px;
			> li.active {
				> a {
					&, &:hover, &:focus {
						background-color: $aux-color;	
					}
				}
			}
			> li {
				> a {
					&:hover, &:focus {
						background-color: $aux-hover-color;
					}
				}
			}
		}
	}
	.modal {
		.panel {
			border-top-color: $aux-color;
			&:before {
				border-top-color: $aux-color;
			}
		}
	}
}
.insp {
	.mainbody {
		.panel {
			border-top-color: $inspection-color;
			&:before {
				border-top-color: $inspection-color;
			}
		}
		.panel-header {
			h3 {
				i {
					background-color: $inspection-color;
					&:after {
						border-left-color: $inspection-color;
					}				
				}
			}
		}
		.nav-tabs {
			margin-bottom: 20px;
			> li.active {
				> a {
					&, &:hover, &:focus {
						background-color: $inspection-color;	
					}
				}
			}
			> li {
				> a {
					&:hover, &:focus {
						background-color: $inspection-hover-color;
					}
				}
			}
		}
	}
	.modal {
		.panel {
			border-top-color: $inspection-color;
			&:before {
				border-top-color: $inspection-color;
			}
		}
	}
}

.ops-detail {
	.panel-header {
		margin-bottom: 8px;
	}
}

/* Ops Detail Status */

.op-status {
	display: inline-block;
	height: 48px;
	background-color: $red;
	color: $white;
	line-height: 28px;
	font-size: 18px;
	text-transform: uppercase;
	padding: 10px 16px 10px 0;
	font-weight: 600;
	position: relative;
	&:after {
		content: "";
		width: 0; 
		height: 0; 
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		border-left: 10px solid $red;
		position: absolute;
		top: 17px;
		right: -10px;
	}
	@media (max-width: $screen-tablet - 1) {
		display: block;
		text-align: center;
		&:after {
			display: none;
		}
	}
	i {
		padding: 0 12px;
	}
}
.op-status.cert {
	background-color: $green;
	&:after {
		border-left-color: $green;
	}
}
.op-statusby {
	font-size: 13px;
	padding-left: 14px;
	display: inline-block;
	@media (max-width: $screen-tablet - 1) {
		display: block;
		text-align: center;
		padding: 15px 0;	
	}
}
.op-action {
	float: right;
	margin-right: 9px;
	margin-top: 9px;
	padding: 5px 20px;
	@media (max-width: $screen-tablet - 1) {
		float: none;
		padding: 10px 0;
		width: 200px;
		margin: 0 auto;
		display: block;	
	}
}


#vendor-info {
	ul {
		> li {
			line-height: 24px;
			position: relative;
			padding-left: 24px;
			> i {
				position: absolute;
				left: 0;
				line-height: 24px;
			}
		}
		> li.vendor-name {
			padding-left: 0;
		}
	}
}
