.profile-details {
  list-style: none;
  padding-top: 15px;
  padding-bottom: 15px;
  padding: 15px 0 15px 0px;
  border-bottom: 1px solid #eee;
}

.profile-detail {
  width: 100%;
  padding: 2px 0 2px 5px;
  overflow-x: hidden;
  white-space: nowrap;
  font-size: 14px;
  text-overflow: ellipsis;
}