/*
 * Override Bootstrap SASS here
 *
 */
 
label {
	font-weight: normal;
}
.pagination > li > a, .pagination > li > span {
	padding: 8px 16px;
}
.nav {
	padding-right: 0;
}
.btn {
	border: none;
}
.dropdown-menu {
  box-shadow: 0 6px 12px rgba(0,0,0,.3);
  > li > a {
	  padding: 8px 20px;
  }
}
 