$font-family-sans-serif: "Open Sans", Helvetica, Arial, sans-serif;

$primary: #F55430;

/*** Grays ***/
$gray:	#ededed;
$light-gray: #F5F5F5;
$mid-gray:  #D5D3D4;
$dark-gray:	#8d8d8d;
$darker-gray: darken($dark-gray,7%);
$darkest-gray: #3d3d3d;

/*** Other PP Colors ***/
$red: #b12025;
$green: #279d85;
$white: #FFF;
$black: #000;
$yellow: #F7CC48;
$warning: #f0ad4e;
$blue: #006DD9;
$alert: #01C7EC;
$btn-blue: #286090;

/*** Operation Colors ***/
$turning-color: #999;
$turning-hover-color: #666;
$milling-color: #3B82A8;
$milling-hover-color: #365F83;
$aux-color: #2A6383;
$aux-hover-color: #1C465D;
$inspection-color: #722022;
$inspection-hover-color: #581819;

$panel-shadow: 0 0 3px 0 rgba(0,0,0,0.5);

/*** Animation Setting ***/
$fade-speed: 2s;