.nav-tabs {
	border-bottom: none;
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.75);
	> li {
		margin-bottom: 0;
		> a {
			background-color: $mid-gray;
			border: none;
			color: $black;
			border: none;
			margin-right: 0;
			transition: all 0.25s ease-in 0s;
			> i {
				padding-right: 4px;
			}
			&:hover, &:focus {
				color: $white;
				border: none;
			}
		}
	}
	> li.active {
		> a {
			border: none;
			&:hover, &:focus {
				border: none;
			}
		}
	}
}
.nav-stacked {
	> li {
		&:first-child {
			> a {
				border-top-left-radius: 4px;
			}
		}
		&:last-child {
			> a {
				border-bottom-left-radius: 4px;			
			}
		}
		+ li {
			margin-top: 0;
		}
		> a {
			border-radius: 0;
		}
	}
}
.tab-pane {
	.panel {
		min-height: 240px;
	}
}

.tab-table {
	.div-table {
		.col-xs-1 {
			width: 8%;
		}
		.col-xs-2 {
			width: 12%;
		}
		.col-xs-7 {
			width: 80%;
		}
	}
	.div-table-cell {
		.fa-chevron-right, .fa-chevron-down {
			cursor: pointer;
			color: $red;
		}
		.dragger {
			padding-right: 0;
			text-align: center;
			.fa-ellipsis-v {
				padding: 0;
			}
		}
	}
	.collapse {
		padding: 10px;
	}
	.sort-row {
		&:nth-child(odd) {
			.div-table {
				background-color: $light-gray;
			}
		}
		&:last-child {
			.div-table {
				border-bottom: 0.1em solid $table-border-color;
			}
		}

		> .row {
			border-bottom: 0.1em solid $table-border-color;
			margin: 0;
			&:last-child {
				border-bottom: none;
			}
		}
	}
}