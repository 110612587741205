/*.form-control {
	color: black;
} */

/* Update chosen sprites */
/*.chosen-rtl .chosen-search input[type="text"],
.chosen-container-single .chosen-single abbr,
.chosen-container-single .chosen-single div b,
.chosen-container-single .chosen-search input[type="text"],
.chosen-container-multi .chosen-choices .search-choice .search-choice-close,
.chosen-container .chosen-results-scroll-down span,
.chosen-container .chosen-results-scroll-up span
{
	background-image: url('chosen-sprite-pp.png');
}*/

/* Update chosen input height */
.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
	height: auto;
}

/* @group Retina compatibility */
/*@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 144dpi) {
  .chosen-rtl .chosen-search input[type="text"],
  .chosen-container-single .chosen-single abbr,
  .chosen-container-single .chosen-single div b,
  .chosen-container-single .chosen-search input[type="text"],
  .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    background-image: url('chosen-sprite-pp@2x.png') !important;
    }
}*/

input {
	background-color: $gray;
	border: 1px solid #CCC;
	padding: 4px 0 4px 10px;
}
/* input, textarea {
	max-width: 95%;
	@media (max-width: $screen-desktop - 1) {
		width: 95%;
	}
} */
form, .tab-table {
	.fa-asterisk {
		color: $red;
		font-size: 10px;
		padding-left: 4px;
	}
}
.form-group {
	margin-bottom: 10px;
	.col-md-8 {
		@media (max-width: $screen-desktop - 1) {
			padding-left: 0;
			padding-right: 0;
		}			
	}
}
.form-panel {
	padding: 15px 20px 0 20px;
	/* > .row.no-gutter {
		margin-left: -20px;
		margin-right: 0;
	} */
	.search-actions {
		margin-bottom: -20px;
	}
	/* .panel-header {
		padding-right: 20px;
	} */
}
.form-control.chosen-container-single {
	background-color: transparent;
	border: none;
	box-shadow: none;
	display: inline-block;
	font-size: 13px;
	height: auto;
	padding: 0;
}
.has-error {
	.form-control.chosen-container-single {
		border: 1px solid #a94442;
	}	
}
.chosen-container-single {
	width: 95%;
	.chosen-single {
		border-radius: $input-border-radius;
		border: 1px solid #CCC;
		padding: 2px 0 2px 10px;
		height: 32px;
		div {
			&:before {
				content: "";
				width: 0; 
				height: 0; 
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				border-top: 6px solid $red;
				position: absolute;
				top: 12px;
				right: 6px;
			}
			b {
				background-image: none !important;
			}
		}

	}
}
.chosen-rtl .chosen-search input[type="text"], .chosen-container-single .chosen-single abbr, .chosen-container-single .chosen-single div b, .chosen-container-single .chosen-search input[type="text"], .chosen-container-multi .chosen-choices .search-choice .search-choice-close, .chosen-container .chosen-results-scroll-down span, .chosen-container .chosen-results-scroll-up span {
	background-image: none !important;
}
.chosen-container.auto-width {

	width: auto !important;
}
.chosen-container-multi {
	.chosen-choices {
		border: 1px solid #CCC;
		padding: 2px 0 2px 10px;
	}
}
.chosen-search {
	&:after {
		content: "\f002"; // search icon
	    font-family: FontAwesome;
		font-style: normal;
		font-weight: normal;
		text-decoration: inherit;
		color: $red;
		position: absolute;
		right: 10px;
		top: 6px;
	}
}
.chosen-container-single {
	.chosen-single {
		abbr {
			top: 8px;
			&:before {
				content: "\f00d"; // close icon
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				text-decoration: inherit;
				color: $red;
				position: absolute;
				left: 0;
				top: -5px;
				font-size: 14px;		
			}
		}
	}
}
.chosen-container-single {
	.chosen-search {
		input[type="text"] {
			max-width: 100%;			
		}
	}
}		
		
.submit-save {
	position: relative;
	display: inline;
	input {
		background-color: transparent;
		padding-left: 18px;
		width: auto;
	}
	&:before {
		content: "\f0c7"; // floppy icon
		font-family: FontAwesome;
		font-style: normal;
		font-weight: normal;
		text-decoration: inherit;
		font-size: 12px;
		position: absolute;
		left: 2px;
		padding-top: 1px;
		cursor: pointer;
	}
}	

.ui-spinner {
	display: block;
	position: relative;
	.ui-spinner-button {
		position: absolute;
		top: 6px;
		cursor: pointer;
		&:hover, &:active {
			text-decoration: none;
		}
		span {
			display: none;
		}
		&:before {
			content: "";
			width: 0; 
			height: 0; 
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			position: absolute;
			top: 8px;
			right: -4px;			
		}
	}
	.ui-spinner-up {
		right: 15%;
		@media (max-width: $screen-desktop - 1) {
			right: 12%;
		}
		@media (max-width: $screen-tablet - 1) {
			right: 12%;
		}
		@media (max-width: 400px) {
			right: 13%;
		}
		&:before {
			border-bottom: 7px solid $red;
		}
	}
	.ui-spinner-down {
		right: 6%;
		&:before {
			border-top: 7px solid $red;
		}
	}
}

/* Time Group */
.time-group {
	.form-group {
		display: inline-block;
	}
	label {
		display: inline-block;
	}
	input.form-control {
		width: 34px;
		display: inline-block;
		margin: 0 10px 10px 0;
		padding: 6px;
	}
}

.red {
  /* font-weight: bold;
  color: $red; */
  /* text-align: right; */
}