.btn-sm.shopping-list-add {
	margin-left: 16px;
	margin-top: -4px;
	vertical-align: top;
	@media (max-width: $screen-tablet - 1) {
		font-size: 11px;
		padding: 5px 7px;
		margin-left: 4px;
		margin-top: 6px;
		margin-bottom: 10px;	
	}
}
.item-status {
	display: block;
	padding-top: 5px;
}
.status-new {
	color: $dark-gray;
}
.status-ordered {
	color: $yellow;
}
.status-received {
	color: $blue;
}
.table-hover {
	tbody {
		tr.edit-form {
			&:nth-of-type(2n+1):hover {
				background-color: $light-gray;
			}
			&:hover {
				background-color: $white;
			}
		}
	}
}
.table {
	> tbody {
		> tr.edit-form {
			> td {
				padding: 12px;
				.well {
					margin-bottom: 0;
				}
			}
		}
	}
}
.shopping-edit {
	.row {
		margin-left: 0;
		margin-right: 0;
	}
	.second-row {
		@media (min-width: $screen-md) {
			margin-top: 20px;
		}			
	}
	.ui-spinner {
		.ui-spinner-down {
			right: 40%;
			@media (max-width: $screen-tablet - 1) {
				right: 32%;
			}
		}
		.ui-spinner-up {
			right: 13%;
			@media (max-width: $screen-tablet - 1) {
				right: 11%;
			}			
		}
	}
}